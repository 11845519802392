



























import Vue from 'vue';

export default Vue.extend({
  name: 'App',

  data: () => ({
    //
  }),

  computed: {
    env(): string {
      if (window.location.href.startsWith('https://workorder.garlandhq.com')) {
        return 'PRODUCTION';
      }
      return 'DEVELOPMENT';
    },
    showEnv(): boolean {
      return this.env !== 'PRODUCTION';
    },
  },

});
